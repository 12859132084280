import { uuid } from './utils'

export default {
  name: 'workshop',
  columns: [
    {
      name: 'Cutting Machine',
      tasks: [
        {
          description: 'Vijay',
          name: 'VH Job 1589',
          timeRemaining: '2',
          processesRemaining: '3',
          id: uuid(),
          userAssigned: null
        },
        {
          description: 'Vijay',
          timeRemaining: '4',
          processesRemaining: '6',
          name: 'VH Job 9874',
          id: uuid(),
          userAssigned: null
        },
        {
          description: 'Giri',
          timeRemaining: '3',
          processesRemaining: '8',
          name: 'PA Job 5693',
          id: uuid(),
          userAssigned: null
        }
      ]
    },
    {
      name: 'Printing Machine',
      tasks: [
        {
          description: 'Vijay',
          timeRemaining: '4',
          processesRemaining: '5',
          name: 'VH Job 7894',
          id: uuid(),
          userAssigned: null
        },
        {
          description: 'Vijay',
          timeRemaining: '10',
          processesRemaining: '2',
          name: 'VH Job 7932',
          id: uuid(),
          userAssigned: null
        },
        {
          description: 'Vijay',
          timeRemaining: '2',
          processesRemaining: '3',
          name: 'VH Job 7547',
          id: uuid(),
          userAssigned: null
        },
        {
          description: 'Vijay',
          timeRemaining: '2',
          processesRemaining: '3',
          name: 'VH Job 8632',
          id: uuid(),
          userAssigned: null
        },
        {
          description: 'Vijay',
          timeRemaining: '2',
          processesRemaining: '3',
          name: 'VH Job 7894',
          id: uuid(),
          userAssigned: null
        }
      ]
    },
    {
      name: 'Punching Machine',
      tasks: [
        {
          description: 'Vijay',
          timeRemaining: '2',
          processesRemaining: '3',
          name: 'first task',
          id: uuid(),
          userAssigned: null
        }
      ]
    }
  ]
}
